import { Box, Button, Divider, Typography } from "@mui/material";

interface HeaderProps {
    showAboutPage:() => void
    showGalleryPage:() => void
}

export const Header = (props: HeaderProps) => {
    return (
        <>
            <Box sx={{
                "display": 'flex',
                "justifyContent": 'space-between',
                "alignItems": 'center'

            }}>
                <Typography variant="h3">
                    Carlson Photo
                </Typography>
                <Box>
                    <Button onClick={props.showAboutPage}>About</Button>
                    <Button onClick={props.showGalleryPage}>Galleries</Button>
                    
                </Box>
            </Box>
            <Divider/>
        </>
    )
}