import { ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import { useState } from 'react';

import { GalleryWithInfoProps, GalleryWithInfo } from "./GalleryWithInfo"

interface GalleryGridProps {
    galleries: GalleryWithInfoProps[]
}

export const GalleryGrid =  (props: GalleryGridProps) => {
    let [openGallery, setOpenGallery] = useState<GalleryWithInfoProps | null>(null)
    let closeGallery = () => {setOpenGallery(null)}

    return (


        openGallery ? <GalleryWithInfo title={openGallery.title} description={openGallery.description} numPhotos={openGallery.numPhotos} pathToFiles={openGallery.pathToFiles} location={openGallery.location} closeGallery={closeGallery}/>:
        <ImageList sx={{ width: 500, height: 450 }}>
            {props.galleries.map((gridProps: GalleryWithInfoProps, id: number) => {
                return (
                    <ImageListItem key={gridProps.title}>
                        <img onClick={() => {setOpenGallery(gridProps)}} src={`${gridProps.pathToFiles}1.jpg`}/>
                        <ImageListItemBar title={gridProps.title} subtitle={gridProps.location} onClick={() => {setOpenGallery(gridProps)}}/>
                    </ImageListItem>
                )
            })
        }
        </ImageList>
    )
}