import { useState } from 'react'

import { createTheme, ThemeProvider, Typography, Container } from "@mui/material";
import { GalleryGrid } from './components/GalleryGrid';
import { Header } from "./components/Header"
import { GalleryWithInfoProps } from './components/GalleryWithInfo';


const getGalleries = () => {
  let galleries: GalleryWithInfoProps[] = [
    {
      title: "Old Family Photos",
      description: "Old Carlson family photos.",
      location: "Earth",
      numPhotos: 12,
      pathToFiles: "galleries/old_family/"
    },
    {
      title: "Montecito Show #2",
      description: "Joe Carlson's second photo  show at Montecito.",
      location: "Yosemite, Korea, Japan",
      numPhotos: 144,
      pathToFiles: "galleries/montecito_show_2/"
    }
  ]
  return galleries
}

function App() {

  const theme = createTheme()

  const [showAbout, setShowAbout] = useState(false)

  const showAboutPage = () => {
    setShowAbout(true)
  }

  const showGalleryPage = () => {
    setShowAbout(false)
  }
  const galleries = getGalleries()

  return (
      <ThemeProvider theme={theme}>
        <Container maxWidth="lg">
          <Header showAboutPage={showAboutPage} showGalleryPage={showGalleryPage}/>
          {showAbout ?
            <><Typography>A website for sharing the photography of Joe Carlson.</Typography>
            <Typography>  Created by Kevin Carlson for Christmas 2022!</Typography></> :
            <GalleryGrid galleries={galleries} />
          }
        </Container>
      </ThemeProvider>
  )
}

export default App;
