import {Button, ImageList, ImageListItem} from '@mui/material';
import { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export interface GalleryWithInfoProps {
    title: string
    description: string
    numPhotos: number
    pathToFiles: string
    closeGallery?: () => void
    location?: string
    
}

export interface ReactPhoto {
    src: string
}

export const GalleryWithInfo = (props: GalleryWithInfoProps) => {
    const [openPhoto, setOpenPhoto] = useState<number | null>(null)
    let photoSrcs: string[] = []
    for(let i = 1; i<=props.numPhotos; i++){
        photoSrcs = photoSrcs.concat(`${props.pathToFiles}${i}.jpg`)
    }


    return (
        <>
            <h2>
                {props.title}
            </h2>
            <h4>
                {props.location}
            </h4>
            <p>{props.description}</p>
            <ImageList sx={{ width: 900 }}>
            {photoSrcs.map((src, idx) => {
                return (
                    <ImageListItem key={idx}>
                        <img onClick={()=> {setOpenPhoto(idx)}}src={src}/>
                    </ImageListItem>
                )
            })}
            </ImageList>
            {(openPhoto != null) &&
                    <Lightbox
                        onImageLoad={() => {
                            window.dispatchEvent(new Event('resize'));
                          }}
                        mainSrc={photoSrcs[openPhoto]}
                        nextSrc={photoSrcs[(openPhoto + 1) % photoSrcs.length]}
                        prevSrc={photoSrcs[(openPhoto + photoSrcs.length - 1) % photoSrcs.length]}
                        onCloseRequest={() => setOpenPhoto(null)}
                        onMovePrevRequest={() =>
                          setOpenPhoto((openPhoto + photoSrcs.length - 1) % photoSrcs.length)
                        }
                        onMoveNextRequest={() =>
                          setOpenPhoto((openPhoto + 1) % photoSrcs.length)
                        }
                    />
            }
            <Button onClick={props.closeGallery}>Close Gallery</Button>
        </>
    )
}